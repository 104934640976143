<template>
  <cp-general-modal
    ref="addToken"
    :ok-disabled="disabled"
    :ok-text="$t('configurationFundraiseToken.addTokenModal.title')"
    :title="$t('configurationFundraiseToken.addTokenModal.title')"
    @onOk="addToken"
  >
    <div class="modal-header-area">
      <img
        :src="getImagePath('add-token-image.png')"
        alt="Document preview"
        class="new-token-icon"
        style="cursor: pointer"
        @click="showPreviewDocModal(rowData.item)"
      >
      <div class="new-token-header">
        {{ $t('configurationFundraiseToken.addTokenModal.newTokenHeader') }}
      </div>
      <div class="msg-area">
        {{ $t('configurationFundraiseToken.addTokenModal.modalMessage') }}
      </div>
    </div>
    <cp-input
      v-model="tokenName"
      :label="$t('configurationFundraiseToken.addTokenModal.input.name')"
      class="token-name-input"
      name="name"
      validate="required|min:3|max:255"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';

import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import staticPathGeneration from '~/mixins/static-path-generation';

export default {
  name: 'AddTokenModal',
  components: {
    CpInput,
    CpGeneralModal,
  },
  mixins: [staticPathGeneration],
  data() {
    return {
      disabled: false,
      tokenName: '',
    };
  },
  methods: {
    ...mapActions('configuration', ['createToken']),
    show() {
      this.$refs.addToken.show();
    },
    addToken() {
      this.createToken({ issuerId: this.$route.params.idIssuer, body: { tokenName: this.tokenName } })
          .then((newTokenId) => {
            this.$emit('on-token-added', newTokenId);
            this.$refs.addToken.hide();
          });
    },
  },
};
</script>

<style>
.new-token-header {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin: 20px;
}

.new-token-icon {
  width: 168px;
  height: 168px;
}

.modal-header-area {
  text-align: center;
}

.msg-area {
  margin: 20px;
}
</style>
